var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-lg-5"},[_c('ValidationProvider',{staticClass:"mb-4 tip-wrapper",attrs:{"tag":"div","name":"收货地址","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"h3"},[_vm._v("收货地址")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfoObj.receiverUserName),expression:"userInfoObj.receiverUserName"}],staticClass:"form-control",attrs:{"type":"text","disabled":!_vm.isEdit,"placeholder":"收货姓名*"},domProps:{"value":(_vm.userInfoObj.receiverUserName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfoObj, "receiverUserName", $event.target.value)}}}),_c('span',{staticClass:"tip-info"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"mb-4 tip-wrapper",attrs:{"tag":"div","name":"收货电话","rules":"required|telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfoObj.receiverPhoneNumber),expression:"userInfoObj.receiverPhoneNumber"}],staticClass:"form-control",attrs:{"type":"text","disabled":!_vm.isEdit,"placeholder":"收货电话*"},domProps:{"value":(_vm.userInfoObj.receiverPhoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfoObj, "receiverPhoneNumber", $event.target.value)}}}),_c('span',{staticClass:"tip-info"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('ValidationProvider',{staticClass:"mb-4 tip-wrapper",attrs:{"tag":"div","name":"省市","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfoObj.receiverProvince),expression:"userInfoObj.receiverProvince"}],attrs:{"hidden":""},domProps:{"value":(_vm.userInfoObj.receiverProvince)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfoObj, "receiverProvince", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfoObj.receiverCity),expression:"userInfoObj.receiverCity"}],attrs:{"hidden":""},domProps:{"value":(_vm.userInfoObj.receiverCity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfoObj, "receiverCity", $event.target.value)}}}),_c('v-distpicker',{attrs:{"province":_vm.userInfoObj.receiverProvince,"city":_vm.userInfoObj.receiverCity,"hide-area":"","disabled":!_vm.isEdit},on:{"selected":_vm.onSelected}}),_c('span',{staticClass:"tip-info"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6 col-lg-7"},[_c('ValidationProvider',{staticClass:"mb-4 tip-wrapper",attrs:{"tag":"div","name":"详细地址","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"h3"},[_vm._v("详细地址")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.userInfoObj.receiverStreet),expression:"userInfoObj.receiverStreet"}],staticClass:"form-control",attrs:{"disabled":!_vm.isEdit,"type":"text","rows":"10","placeholder":"详细地址*"},domProps:{"value":(_vm.userInfoObj.receiverStreet)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userInfoObj, "receiverStreet", $event.target.value)}}}),_c('span',{staticClass:"tip-info"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }